import React from 'react';
import { css } from '@emotion/core';
import { SectionHeader, SectionDivider } from 'src/components/pages/index';
import { UbicacionSection } from './UbicacionSection';
import { HistoriaSection } from './HistoriaSection';
import { DescripcionSection } from './DescripcionSection';
import { SubsectionDivider } from './SubsectionDivider';
import { EstudiosSection } from './EstudiosSection';
import { ResultsSection } from './ResultsSection';

import { useText } from 'src/components/TextContext';

export const ClusterDragonContent = () => {
  const t = useText();
  return (
    <div
      css={css`
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 1rem;
      `}
    >
      <SectionHeader>{t.clusterDragon.title}</SectionHeader>
      <SectionDivider />
      <div
        css={css`
          display: grid;
          margin: auto;
          padding: 0 1rem;
          grid-template-columns: 1fr;
          width: min(100%, 80rem);
        `}
      >
        <SubsectionDivider />
        <UbicacionSection />
        <SubsectionDivider />
        <HistoriaSection />
        <SubsectionDivider />
        <DescripcionSection />
        <SubsectionDivider />
        <EstudiosSection />
        <ResultsSection />
      </div>
    </div>
  );
};

export default ClusterDragonContent;
