import React, { useState } from 'react';
import { css } from '@emotion/core';
import { SubsectionHeader } from './SubsectionHeader';
import { useText, useLanguage } from 'src/components/TextContext';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import imgEstudios00 from '../../../../images/cluster-dragon/estudios00.jpg';
import imgEstudios01 from '../../../../images/cluster-dragon/estudios01.jpg';
import imgEstudios02 from '../../../../images/cluster-dragon/estudios02.jpg';
import imgEstudios03 from '../../../../images/cluster-dragon/estudios03.jpg';
import imgEstudiosZoom00 from '../../../../images/cluster-dragon/estudios-zoom00.jpg';
import imgEstudiosZoom01 from '../../../../images/cluster-dragon/estudios-zoom01.jpg';
import imgEstudiosZoom02 from '../../../../images/cluster-dragon/estudios-zoom02.jpg';
import imgEstudiosZoom03 from '../../../../images/cluster-dragon/estudios-zoom03.jpg';
import imgEstudios00en from '../../../../images/cluster-dragon/estudios-en00.jpg';
import imgEstudios01en from '../../../../images/cluster-dragon/estudios-en01.jpg';
import imgEstudios02en from '../../../../images/cluster-dragon/estudios-en02.jpg';
import imgEstudios03en from '../../../../images/cluster-dragon/estudios-en03.jpg';
import imgEstudiosZoom00en from '../../../../images/cluster-dragon/estudios-zoom-en00.jpg';
import imgEstudiosZoom01en from '../../../../images/cluster-dragon/estudios-zoom-en01.jpg';
import imgEstudiosZoom02en from '../../../../images/cluster-dragon/estudios-zoom-en02.jpg';
import imgEstudiosZoom03en from '../../../../images/cluster-dragon/estudios-zoom-en03.jpg';

export const EstudiosSection = () => {
  const t = useText();
  const { currentLanguage } = useLanguage();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const images =
    currentLanguage == 'es'
      ? [imgEstudios00, imgEstudios01, imgEstudios02, imgEstudios03]
      : [imgEstudios00en, imgEstudios01en, imgEstudios02en, imgEstudios03en];
  const imagesZoom = currentLanguage == 'es'
    ? [
        imgEstudiosZoom00,
        imgEstudiosZoom01,
        imgEstudiosZoom02,
        imgEstudiosZoom03,
      ]
    : [
        imgEstudiosZoom00en,
        imgEstudiosZoom01en,
        imgEstudiosZoom02en,
        imgEstudiosZoom03en,
      ];
  const EstudioImg = ({ index }) => (
    <div
      tabIndex={0}
      css={css`
        margin: auto;
      `}
    >
      <img
        css={css`
          cursor: pointer;
          width: 100%;
        `}
        src={images[index]}
        onClick={() => {
          setOpen(true);
          setPhotoIndex(index);
        }}
      />
    </div>
  );

  return (
    <div
      css={css`
        padding: 1rem;
        display: flex;
        flex-direction: column;
      `}
    >
      <SubsectionHeader
        title={t.estudios.title}
        subtitle={t.estudios.subtitle}
      />
      <div
        css={css`
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          max-width: 80%;
          margin: auto;
        `}
      >
        {images.map((x, i) => (
          <EstudioImg index={i} key={i} />
        ))}

        {open && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
            mainSrc={imagesZoom[photoIndex]}
            onCloseRequest={() => setOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default EstudiosSection;
