import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { SectionHeader } from 'src/components/pages/index';
import { toParagraphsArray } from 'src/util';
import { MAIN_COLOR } from 'src/consts';
import { SubsectionHeader } from './SubsectionHeader';
import { useText } from 'src/components/TextContext';
import Lightbox from 'react-image-lightbox';

import Ubicacion from '../../../../images/cluster-dragon/ubicacion.jpg';
import UbicacionDetalle from '../../../../images/cluster-dragon/ubicacion-detalle.jpg';

export const UbicacionSection = () => {
  const t = useText();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          max-width: 70rem;
        `}
      >
        <div
          css={css`
            padding: 1rem;
          `}
        >
          <SubsectionHeader title={t.ubicacion.title} />
          <div>
            {toParagraphsArray(t.ubicacion.content).map((x, i) => (
              <Typography
                key={i}
                css={css`
                  font-size: 18px;
                  white-space: pre-wrap;
                  margin: auto;
                  text-align: justify;
                  margin-bottom: 1rem;
                `}
              >
                {x}
              </Typography>
            ))}
            <ul>
              {t.ubicacion.moreDetails.map((x, i) => (
                <li key={i}>
                  <Typography>{x}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          css={css`
            padding: 1rem;
            display: flex;
            flex-direction: column;
          `}
        >
          <Typography align="center" variant="h5">
              <i>{t.ubicacion.enlarge}</i>
          </Typography>
          <img
            css={css`
              max-width: 100%;
              cursor: pointer;
            `}
            onClick={() => {
              setOpen(true);
            }}
            src={Ubicacion}
          />
        </div>
        {open && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
            mainSrc={UbicacionDetalle}
            onCloseRequest={() => setOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default UbicacionSection;
