import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { SectionHeader } from 'src/components/pages/index';
import Historia from '../../../../images/cluster-dragon/historia.jpg';
import { toParagraphsArray } from 'src/util';
import { SubsectionHeader } from './SubsectionHeader';
import { useText } from 'src/components/TextContext';


// const UbicacionSection = (t={}) => {

// }

export const HistoriaSection = () => {
  const t = useText();
  return (
    <div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          max-width: 70rem;
        `}
      >
        <div
          css={css`
            padding: 1rem;
          `}
        >
          <SubsectionHeader title={t.historia.title} />
          {toParagraphsArray(t.historia.content).map((x, i) => (
            <Typography
              key={i}
              css={css`
                font-size: 18px;
                white-space: pre-wrap;
                margin: auto;
                text-align: justify;
                margin-bottom: 1rem;
              `}
            >
              {x}
            </Typography>
          ))}
        </div>
        <div
          css={css`
            padding: 1rem;
          `}
        >
          <img
            css={css`
              max-width: 100%;
            `}
            src={Historia}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoriaSection;
