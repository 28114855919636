import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { MAIN_COLOR } from 'src/consts';

export const SubsectionHeader = ({ title = '', subtitle = null }) => {
  return (
    <div>
      <Typography
        variant="h4"
        css={css`
          text-transform: uppercase;
          font-weight: bold;
        `}
      >
        {title}{' '}
        {subtitle && (
          <i
            css={css`
              text-transform: none;
              font-weight: normal;
            `}
          >
            {subtitle}
          </i>
        )}
      </Typography>
      <div
        css={css`
          width: 3rem;
          margin: 1rem 0;
          border-top-width: 0.4rem;
          border-top-color: ${MAIN_COLOR};
          border-top-style: solid;
        `}
      />
    </div>
  );
};
