import React from 'React';
import styled from '@emotion/styled';
import { MAIN_COLOR } from 'src/consts';

export const SubsectionDivider = styled.div`
  margin: 1rem 0;
  width: 100%;
  border-top-width: 0.2rem;
  border-top-color: ${MAIN_COLOR};
  border-top-style: solid;
  opacity: 0.4;
`;

export default SubsectionDivider;
