import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { ClusterDragonContent } from 'src/components/pages/cluster-dragon';
import { TextContextProvider } from 'src/components/TextContext';
import esTextCommon from '../../text/es/common.yaml';
import esTextClusterDragon from '../../text/es/cluster-dragon.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextClusterDragon from '../../text/en/cluster-dragon.yaml';

const esText = { ...esTextCommon, ...esTextClusterDragon };
const enText = { ...enTextCommon, ...enTextClusterDragon };
const text = {
  es: esText,
  en: enText,
};

const ClusterDragonPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 6rem;
              max-width: 100vw;
            `}
          >
            <ClusterDragonContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default ClusterDragonPage;
