import React from 'react';
import { css } from '@emotion/core';
import { SubsectionHeader } from './SubsectionHeader';
import { useText, useLanguage } from 'src/components/TextContext';
import 'react-image-lightbox/style.css';

import imgResultados00 from '../../../../images/cluster-dragon/resultados00.jpg';
import imgResultados00en from '../../../../images/cluster-dragon/resultados-en00.jpg';

export const ResultsSection = () => {
  const t = useText();
  const { currentLanguage } = useLanguage();

  const images =
    currentLanguage == 'es' ? [imgResultados00] : [imgResultados00en];

  const ResultsImg = ({ index }) => (
    <div
      css={css`
        margin: auto;
      `}
    >
      <img
        css={css`
          width: 100%;
        `}
        src={images[index]}
      />
    </div>
  );

  return (
    <div
      css={css`
        padding: 1rem;
        display: flex;
        flex-direction: column;
      `}
    >
      <SubsectionHeader title={t.resultados.title} />
      <div
        css={css`
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          max-width: 80%;
          margin: auto;
        `}
      >
        {images.map((x, i) => (
          <ResultsImg index={i} key={x} />
        ))}
      </div>
    </div>
  );
};

export default ResultsSection;
